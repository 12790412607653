import {alpha, createTheme} from "@mui/material";
import {grey, orange} from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }

    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }
}

let theme = createTheme({
    palette: {
        primary: {
            light: alpha("#D87C6B", 0.1),
            main: "#FFFFFF",
            dark: "#2F4656"
        },
        secondary: {
            light: orange[100],
            main: orange[500],
            dark: orange[700]
        },
        neutral: {
            light: grey[100],
            main: "whitesmoke",
            dark: "lightgrey"
        },
        mode: "dark"
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: "0.875rem",
                    lineHeight: 1.43,
                    letterSpacing: "0.01071em"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    "&.Mui-disabled": {
                        background: "#d3d3d34d",
                    },
                },
                colorSecondary: {
                    backgroundColor: alpha(theme.palette.secondary.light, 0.5)
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ".MuiInputBase-rootMui-disabled": {
                        paddingRight: "39px",
                    },
                },
                groupLabel: {
                    backgroundColor: theme.palette.neutral.main
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.main
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                toolbarContainer: {
                    backgroundColor: theme.palette.neutral.main
                },
                footerContainer: {
                    backgroundColor: theme.palette.neutral.main
                },
                pinnedColumnHeaders: {
                    backgroundColor: theme.palette.neutral.main
                },
                columnHeaders: {
                    backgroundColor: theme.palette.neutral.main
                },
                columnHeader: {
                    "&:focus": {
                        outline: "none"
                    },
                    "&:focus-within": {
                        outline: "none"
                    }
                },
                cell: {
                    "&:focus": {
                        outline: "none"
                    },
                    "&:focus-within": {
                        outline: "none"
                    }
                },
                row: {
                    cursor: "pointer"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.neutral.main,
                    padding: "8px 16px"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    display: "flex",
                    padding: "8px 24px"
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.neutral.main
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: "hover"
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.neutral.main
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: theme.palette.secondary.light
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: theme.palette.secondary.light
                    },
                    "&:last-child td": {
                        border: 0
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                stickyHeader: {
                    backgroundColor: theme.palette.neutral.main
                }
            }
        }
    }
});

export default theme;

import React from "react";
import {Box, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";

const SanValentino = (): JSX.Element => {
    return (
        <Stack justifyContent="center" spacing={2} padding={2}>
            <Typography variant="h4" align="center" fontWeight="bold" fontFamily="papyrus" color="darkred" gutterBottom>SAN VALENTINO</Typography>
            <Box component={Paper} elevation={5} padding={2}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={4} minHeight={400}>
                        <AutoSizer>
                            {({width, height}) =>
                                <Box width={width} height={height} display="flex" justifyContent="center">
                                    <img
                                        src="/processione.jpg"
                                        style={{width: "auto", maxWidth: width, height: "auto", maxHeight: height, borderRadius: 5}}
                                        alt="San Valentino"
                                    />
                                </Box>
                            }
                        </AutoSizer>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="body1" paddingBottom={2}>
                            San Valentino visse a Roma tra il III e il IV secolo.
                        </Typography>
                        <Typography variant="body1">
                            Si narra che l’imperatore Claudio II, incuriosito dalla fama di santità di questo prete romano, volle vederlo ed interrogarlo personalmente.
                        </Typography>
                        <Typography variant="body1" paddingBottom={2}>
                            Valentino dimostrò la falsità delle divinità pagane e parlò del suo Dio con una eloquenza tale da indurre Claudio II a convertirsi.
                        </Typography>
                        <Typography variant="body1">
                            L'imperatore, richiamato ai doveri di persecutore, congedò il Santo affidandolo al prefetto che a sua volta, lo congedò a un ministro di nome Asterio.
                        </Typography>
                        <Typography variant="body1">
                            Questi, impressionato dal suo fervore gli chiese di ridare la vista alla figlia cieca dall’età di due anni.
                        </Typography>
                        <Typography variant="body1" paddingBottom={2}>
                            Valentino si raccolse in preghiera e passò le mani sugli occhi della fanciulla che ritornò a vedere: questo miracolo spinse tutta la famiglia a
                            convertirsi.
                        </Typography>
                        <Typography variant="body1">
                            Quando l’imperatore venne a sapere della conversione, ordinò che Valentino venisse decapitato.
                        </Typography>
                        <Typography variant="body1">
                            Fu poi sepolto nei pressi della via Flaminia, dove papa Giulio fece edificare una basilica.
                        </Typography>
                        <Divider sx={{padding: 2}}/>
                        <Typography variant="body1" paddingY={2}>
                            La legenda del grano è la genesi del legame tra San Valentino e Abriola.
                        </Typography>
                        <Typography variant="body1">
                            Si narra che mentre Abriola era in un periodo di rigida carestia, un uomo si recò nelle zone marine dove commissionò a nome di Valentino il romano, due
                            carri di
                            grano da portare ad Abriola.
                        </Typography>
                        <Typography variant="body1" paddingBottom={2}>
                            Non avendo denaro lasciò in pegno un prezioso anello.
                        </Typography>
                        <Typography variant="body1">
                            Dopo alcuni giorni i carri giunsero ad Abriola e i contadini chiesero di Valentino il romano, ma nessuno lo conosceva.
                        </Typography>
                        <Typography variant="body1" paddingBottom={2}>
                            Nella loro ricerca essi passarono davanti la chiesa, entrarono e con grande stupore riconobbero nell’uomo raffigurato dalla statua di San Valentino
                            colui che
                            aveva commissionato il grano.
                        </Typography>
                        <Typography variant="body1" paddingBottom={2}>
                            Resisi conto di aver trattato con un Santo, i contadini restituirono l’anello che aveva dato in pegno e lasciarono il grano senza nulla pretendere in
                            cambio.
                        </Typography>
                        <Typography variant="body1">
                            Ogni anno, il 14 febbraio, gli abitanti di Abriola festeggiano San Valentino con grande devozione e coinvolgimento.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

export default SanValentino;

import React from "react";
import {Card, CardActionArea, CardContent, CardMedia, Fade, Grid, Grow, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";

const HomePage = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const muiTheme = useTheme();
    const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"));

    const cards = [
        {image: "/card3.jpg", path: "mappa", title: "Mappa Fucanoj", description: "Mappa del percorso itinerante del 14 febbraio tra calore, emozioni, gusto e tradizione"},
        {image: "/card2.jpg", path: "programma", title: "Programma eventi", description: "Tutti gli eventi in programma per celebrare tutte le sfumature dell'amore"},
        {image: "/card1.jpeg", path: "san-valentino", title: "Il Santo", description: "Storia di San Valentino"}
    ]

    const handleClick = (path: string) => (): void => {
        if (location.pathname !== `/${path}`)
            navigate(`/${path}`);
    }

    return (
        <Grid container direction="column" justifyContent="center" padding={2}>
            <Fade in style={{transformOrigin: '0 0 0'}} timeout={1000}>
                <Typography variant={isDownSm ? "h6" : "h3"} align="center" color="darkred" fontFamily="papyrus" fontStyle="italic">Tutte le sfumature dell'amore</Typography>
            </Fade>
            <Fade in style={{transformOrigin: '0 0 0'}} timeout={1000}>
                <Typography variant={isDownSm ? "subtitle2" : "h6"} align="center" color="darkred" fontFamily="papyrus">Abriola</Typography>
            </Fade>
            <Fade in style={{transformOrigin: '0 0 0'}} timeout={1000}>
                <Typography variant={isDownSm ? "subtitle2" : "h6"} align="center" color="darkred" fontFamily="papyrus">10-14 febbraio 2023</Typography>
            </Fade>
            <Grid container item height="100%" alignItems="center" justifyContent="center">
                {cards.map(card =>
                    <Grid key={card.title} item xs={12} md={4} paddingX={5} paddingY={2}>
                        <Grow in style={{transformOrigin: '0 0 0'}} timeout={1000}>
                            <CardActionArea onClick={handleClick(card.path)}>
                                <Card sx={{height: 520}} elevation={5}>
                                    <CardMedia
                                        height={390}
                                        sx={{objectFit: "fill"}}
                                        component="img"
                                        image={card.image}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {card.title}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {card.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grow>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default HomePage;

import React, {useState} from "react";
import theme from "./themes";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AppBar, BottomNavigation, BottomNavigationAction, Box, CssBaseline, Link, Stack, ThemeProvider, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Church, Email, EventNote, Home, MapOutlined} from "@mui/icons-material";
import {Wrapper} from "@googlemaps/react-wrapper";
import AbriolaMap from "./AbriolaMap";
import CustomDrawer from "./CustomDrawer";
import HomePage from "./HomePage";
import SanValentino from "./SanValentino";
import Schedule from "./Schedule";

const appSx = {
    backgroundTop: {
        background: "linear-gradient(to bottom, black, darkred)"
    },
    backgroundBottom: {
        background: "linear-gradient(to top, black, darkred)"
    },
    bottomNavigation: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
    }
};

const App = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const muiTheme = useTheme();
    const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"));
    const [value, setValue] = useState<number>(0);
    const center = {lat: 40.50735, lng: 15.81388};
    const zoom = 16.4;

    const handleClick = (path: string) => (): void => {
        if (location.pathname !== `/${path}`)
            navigate(`/${path}`);
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box width="100vw" height="100vh" display="flex" flexDirection="column">
                <AppBar position="relative" sx={appSx.backgroundTop}>
                    <Toolbar>
                        <CustomDrawer/>
                        <Stack direction="row" spacing={3} alignItems="center" padding={isDownSm ? 2 : 0}>
                            {!isDownSm &&
                                <Box height="100%" display="flex" alignItems="flex-end" pt={1.5}>
                                    <img src="/san-valentino.png" height="80px" alt="san valentino abriola" onClick={() => navigate("/")}/>
                                </Box>
                            }
                            <img src="/logo.png" height="70px" alt="san valentino abriola" onClick={() => navigate("/")}/>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Box flexGrow={1} overflow="auto">
                    <Routes>
                        <Route path="/san-valentino" element={<SanValentino/>}/>
                        <Route path="/programma" element={<Schedule/>}/>
                        <Route path="/mappa" element={process.env.REACT_APP_GOOGLE_API_KEY &&
                            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                                <AbriolaMap center={center} zoom={zoom}/>
                            </Wrapper>
                        }/>
                        <Route index element={<HomePage/>}/>
                    </Routes>
                </Box>
                {isDownSm ?
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        sx={{...appSx.backgroundBottom, width: "100%", minHeight: 65}}
                    >
                        <BottomNavigationAction label="Home" icon={<Home/>} onClick={handleClick("")}/>
                        <BottomNavigationAction label="Mappa Fucanoj" icon={<MapOutlined/>} onClick={handleClick("mappa")}/>
                        <BottomNavigationAction label="Programma" icon={<EventNote/>} onClick={handleClick("programma")}/>
                        <BottomNavigationAction label="San Valentino" icon={<Church/>} onClick={handleClick("san-valentino")}/>
                    </BottomNavigation>
                    :
                    <Stack direction="row" minHeight={65} sx={{...appSx.backgroundBottom, paddingX: 4, paddingY: 2}} justifyContent="space-between">
                        <Typography variant="subtitle1" color="lightgrey" fontStyle="inherit" fontFamily="Papyrus">
                            La misura dell'amore è amare senza misura. (Sant'Agostino)
                        </Typography>
                        <Stack direction="row" alignItems="center" marginTop="auto" padding={2} spacing={2}>
                            <Email/>
                            <Link href="mailto:sanvalentinoabriola@altervista.org" color="lightgrey" fontFamily="Papyrus" variant="body1">
                                sanvalentinoabriola@altervista.org
                            </Link>
                        </Stack>
                    </Stack>
                }
                {/*<Stack direction="row" alignItems="center" spacing={3} padding={2}>
                        <Link href="http://www.comune.abriola.pz.it" target="_blank">
                            <img src="/comune-abriola.png" height="60px" alt="stemma comune abriola"/>
                        </Link>
                        <Link href="https://www.regione.basilicata.it" target="_blank">
                            <img src="/regione-basilicata.png" height="50px" alt="stemma regione basilicata"/>
                        </Link>
                        <Link href="https://www.facebook.com/profile.php?id=100064750417306" target="_blank">
                            <img src="/pro-loco-abriola.png" height="50px" alt="pro loco abriola"/>
                        </Link>
                    </Stack>*/}
            </Box>
        </ThemeProvider>
    );
}

export default App;

import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Stack, Typography} from "@mui/material";
import {ExpandMore, Place} from "@mui/icons-material";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, timelineOppositeContentClasses, TimelineSeparator} from "@mui/lab";

const schedule = [
    {
        id: "10-febbraio",
        day: "10",
        month: "FEB",
        title: `"Amore per la vita"`,
        subtitle: "a cura dell'AVIS comunale",
        events: [
            {
                hour: "Ore 17:00",
                description: "Convegno sul tema della donazione del sangue con interventi istituzionali",
                location: "Palazzetto dello Sport"
            },
            {
                hour: "Ore 18:00",
                description: "Partita del cuore",
                location: "Palazzetto dello Sport"
            }
        ]
    },
    {
        id: "11-febbraio",
        day: "11",
        month: "FEB",
        title: `"Amore per il prossimo"`,
        subtitle: "a cura dell'A.P.A.",
        events: [
            {
                hour: "Ore 9:30",
                description: "Giornata della salute",
                location: "Palazzo Marinelli"
            },
            {
                hour: "Ore 20:30",
                description: `Spettacolo "A cena con il fantasma" dell'associazione teatrale Arcoscenico`,
                location: "Cineteatro San Giuseppe"
            },
            {
                hour: "Ore 22:30",
                description: "La serata continuerà con animazione e divertimento in vari punti del paese",
                location: "Abriola"
            }
        ]
    },
    {
        id: "12-febbraio",
        day: "12",
        month: "FEB",
        title: `"Amore per la cultura"`,
        subtitle: "",
        events: [
            {
                hour: "Ore 18:00",
                description: `Presentazione del libro "Il nido delle cicogne" di Maria Pia Troccoli`,
                location: "Salone Comunale"
            },
            {
                hour: "Ore 20:30",
                description: `Rappresentazione teatrale "La leggenda del grano"`,
                location: "Cineteatro San Giuseppe"
            }
        ]
    },
    {
        id: "13-febbraio",
        day: "13",
        month: "FEB",
        title: `"Amore per le donne e per la storia"`,
        subtitle: "a cura della Pro Loco",
        events: [
            {
                hour: "Ore 17:00",
                description: `ONE BILLION RISING: Abriola dice no alla violenza sulle donne con lo spettacolo "nemmeno con un dito"`,
                location: "Cineteatro San Giuseppe"
            },
            {
                hour: "Ore 18:00",
                description: "Più sicura: laboratorio di arti marziali e psicologia della difesa personale in collaborazione con kamasport di Calvello, con la partecipazione di Serena Lamastra e Nicola Palermo. Parteciperà il campione del mondo di MMA 2022 Donatello Angerame",
                location: "Cineteatro San Giuseppe"
            },
            {
                hour: "Ore 19:00",
                description: "Fiaccolata dell'Amore",
                location: "Impianti Sciistici - Sellata"
            },
            {
                hour: "Ore 21:00",
                description: `Spettacolo teatrale "Visione trasmutata e accadimenti del brigantaggio abriolano" tratto da "Il barone Federici" di Egidio e Anna Rosa Pomponio`,
                location: "Cineteatro San Giuseppe"
            }
        ]
    },
    {
        id: "14-febbraio",
        day: "14",
        month: "FEB",
        title: "Celebrazioni in onore del Patrono San Valentino",
        subtitle: "",
        events: [
            {
                hour: "Ore 09:00",
                description: "Santa Messa",
                location: "Chiesa Madre Santa Maria Maggiore"
            },
            {
                hour: "Ore 10:30",
                description: "Santa Messa. A seguire Processione di San Valentino per le vie cittadine",
                location: "Chiesa Madre Santa Maria Maggiore"
            },
            {
                hour: "Ore 17:00",
                description: `"Albero dell'amore" postazione di promozione del territorio e distribuzione di gadget alle coppie che visiteranno il borgo`,
                location: "Sede Pro Loco Abriola"
            },
            {
                hour: "Ore 17:30",
                description: "Santa Messa dedicata al Santo Patrono San Valentino, celebrata dall'Arcivescovo Metropolita S.E. Mons. Salvatore Ligorio. Benedizione dei fidanzati e dei coniugi per l'anniversario del 25° e 50° anno di matrimonio.",
                location: "Chiesa Madre Santa Maria Maggiore"
            },
            {
                hour: "Ore 19:00",
                description: `Accensione fucanoj e percorso enogastronomico - Durante la serata presso vari punti inseriti nel percorso dei fucanoj spettacoli con artisti di strada e gruppi itineranti.`,
                location: "presso i vari rioni"
            },
            {
                hour: "Ore 19:30",
                description: `50 SFUMATURE D'AMORE - Le coppie confermeranno il loro legame attraverso alcune prove tra i fucanoj del borgo. Date prova del vostro amore.`,
                location: "Start San Sebastiano"
            },
            {
                hour: "Start ore 20:00",
                description: `Degustazione "chocolate selection", chocco fontane & pop corn al cioccolato`,
                location: `Cioccolateria "Le Delizie di San Valentino`
            },
            {
                hour: "Ore 24:00",
                description: `Accensione delle Lanterne e brindisi dell'amore stellato: lanterne volanti e messaggi d'amore saranno liberati in cielo sulle note di musica della tradizione popolare o d'autore, dedicata all'amore tra i popoli. Fuochi pirotecnici.`,
                location: `presso i vari rioni`
            }
        ]
    }
];

const scheduleSx = {
    avatar: {
        background: "darkred",
        width: 60,
        height: 60,
        color: "white",
        marginRight: 2
    }
};

const Schedule = (): JSX.Element => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean): void => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Stack justifyContent="center" spacing={2} padding={2}>
            <Typography variant="h4" align="center" fontWeight="bold" fontFamily="papyrus" color="darkred" gutterBottom>PROGRAMMA</Typography>
            {schedule.map(daySchedule =>
                <Accordion key={daySchedule.id} expanded={expanded === daySchedule.id} onChange={handleChange(daySchedule.id)}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <Avatar variant="rounded" sx={scheduleSx.avatar}>
                            <Stack alignItems="center">
                                <Typography variant="body1" fontSize={24} fontWeight="bold" lineHeight={1}>{daySchedule.day}</Typography>
                                <Typography variant="button" lineHeight={1.5}>{daySchedule.month}</Typography>
                            </Stack>
                        </Avatar>
                        <Stack spacing={0.5}>
                            <Typography variant="h6">{daySchedule.title}</Typography>
                            <Typography variant="body2" fontStyle="italic" color="lightgray">{daySchedule.subtitle}</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.2,
                                },
                            }}>
                            {daySchedule.events.map((event, index) =>
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent>{event.hour}</TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot/>
                                        {index !== daySchedule.events.length - 1 && <TimelineConnector/>}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Stack spacing={1.5}>
                                            <Typography>{event.description}</Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Place sx={{color: "darkred"}}/>
                                                <Typography variant="caption">{event.location}</Typography>
                                            </Stack>
                                        </Stack>
                                    </TimelineContent>
                                </TimelineItem>
                            )}
                        </Timeline>
                    </AccordionDetails>
                </Accordion>
            )}
        </Stack>
    );
}

export default Schedule;

import React, {useEffect, useRef, useState} from "react";
import {renderToString} from "react-dom/server";
import {Box} from "@mui/material";
import {MyLocation} from "@mui/icons-material";
import InfoWindowContent from "./InfoWindowContent";

interface AbriolaMapProps {
    center: google.maps.LatLngLiteral;
    zoom: number;
}

enum PointType {
    EVENT = "event",
    FUCANOJ = "fucanoj",
    MUSEUM = "museum"
}

const points = [
    {id: "fucanoj-1", type: PointType.FUCANOJ, title: "Fucanoj di San Sebastiano", description: "", position: {lat: 40.50896, lng: 15.81042}},
    {id: "fucanoj-2", type: PointType.FUCANOJ, title: "Fucanoj di San Gerardo", description: "", position: {lat: 40.50791, lng: 15.81216}},
    //{id: "fucanoj-3", type: PointType.FUCANOJ, title: "Fucanoj di San Pietro", description: "", position: {lat: 40.50834, lng: 15.81425}},
    {id: "fucanoj-4", type: PointType.FUCANOJ, title: "Fucanoj della Piazza", description: "", position: {lat: 40.5075, lng: 15.81473}},
    {id: "fucanoj-5", type: PointType.FUCANOJ, title: "Fucanoj della Consolata", description: "", position: {lat: 40.507, lng: 15.81683}},
    //{id: "fucanoj-6", type: PointType.FUCANOJ, title: "Fucanoj di San Martino", description: "", position: {lat: 40.50668, lng: 15.81827}},
    {id: "fucanoj-7", type: PointType.FUCANOJ, title: "Fucanoj del Convento", description: "", position: {lat: 40.50635, lng: 15.81416}},
    {id: "event-1", type: PointType.EVENT, title: "Artisti di strada", description: "Parcheggio comunale", position: {lat: 40.50832, lng: 15.81248}},
    {id: "event-2", type: PointType.EVENT, title: "Artisti di strada", description: "Scuola dell'infanzia", position: {lat: 40.50647, lng: 15.81445}},
    {id: "event-3", type: PointType.EVENT, title: "Artisti di strada", description: "Piazza Tocco", position: {lat: 40.50777, lng: 15.81382}},
    {id: "museum-1", type: PointType.MUSEUM, title: "Palazzo De Stefano", description: "", position: {lat: 40.50798, lng: 15.81319}}
];

const AbriolaMap = (props: AbriolaMapProps): JSX.Element => {
    const {center, zoom} = props;
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [currentPosition, setCurrentPosition] = useState<google.maps.LatLngLiteral>();

    const buildNavigationUrl = (destination: google.maps.LatLngLiteral, origin?: google.maps.LatLngLiteral): string => {
        const baseUrl = "https://www.google.com/maps/dir/?api=1";
        const originString = origin ? `&origin=${origin.lat},${origin.lng}` : "";
        const destinationString = `&destination=${destination.lat},${destination.lng}`;
        const dirActionString = "&dir_action=navigate";
        const travelModeString = "&travelmode=walking";
        return baseUrl + originString + destinationString + dirActionString + travelModeString;
    }

    const handleLocationError = (browserHasGeolocation: boolean, infoWindow: google.maps.InfoWindow, pos: google.maps.LatLng): void => {
        infoWindow.setPosition(pos);
        infoWindow.setContent(browserHasGeolocation ?
            "Error: The Geolocation service failed." :
            "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
    }

    const getIcon = (pointType: PointType) => {
        switch (pointType) {
            case PointType.EVENT:
                return "/event-48x48.png";
            case PointType.FUCANOJ:
                return "/fucanoj-48x48.png";
            case PointType.MUSEUM:
                return "/museum-48x48.png";
        }
    }

    useEffect(() => {
        if (ref.current && !map) {
            const map = new window.google.maps.Map(ref.current as HTMLElement, {
                center: center,
                zoom: zoom,
                mapTypeId: "satellite",
                panControl: true
            });

            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();
            const infoWindow = new google.maps.InfoWindow();

            const locationButton = document.createElement("button");
            locationButton.title = "Localizzami sulla mappa";
            locationButton.style.marginRight = "10px";
            locationButton.style.padding = "7px 7px";
            locationButton.style.color = "darkred";
            locationButton.innerHTML = renderToString(<MyLocation/>);
            map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);

            locationButton.addEventListener("click", () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: GeolocationPosition) => {
                            const currentPosition = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            const marker = new window.google.maps.Marker({
                                map,
                                position: currentPosition,
                                title: "Io sono qui",
                                icon: "/position-48x48.png"
                            });
                            setCurrentPosition(currentPosition);
                            map.setCenter(currentPosition);
                        },
                        () => {
                            handleLocationError(true, infoWindow, map.getCenter()!)
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter()!);
                }
            });

            points.forEach(point => {
                const marker = new window.google.maps.Marker({
                    map,
                    position: point.position,
                    title: point.title,
                    icon: getIcon(point.type)
                });

                marker.addListener("click", () => {
                    const infoWindowContent = <InfoWindowContent id={point.id} title={point.title} description={point.description}/>
                    const content = renderToString(infoWindowContent);
                    infoWindow.setContent(content);
                    infoWindow.open({map, anchor: marker});

                    if (currentPosition) {
                        directionsService.route({
                            origin: currentPosition,
                            destination: point.position,
                            travelMode: google.maps.TravelMode.WALKING,
                            provideRouteAlternatives: true,
                        }).then(response => directionsRenderer.setDirections(response));
                    }
                });

                google.maps.event.addListener(infoWindow, "domready", () => {
                    document.getElementById(point.id)?.addEventListener("click", () => {
                        const navigationUrl = buildNavigationUrl(point.position, currentPosition);
                        window.open(navigationUrl, "_blank");
                    });
                });
            });

            directionsRenderer.setMap(map);
            setMap(map);
        }
    }, [ref, map]);

    return <Box width="100%" height="100%" ref={ref} id="map"/>;
}

export default AbriolaMap;

import React, {useState} from "react";
import {Box, Divider, Drawer, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Stack, Theme} from "@mui/material";
import {ChevronLeft as ChevronLeftIcon, Church, Email, EventNote, MapOutlined, Menu as MenuIcon} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";

const DRAWER_WIDTH = 350;

const drawerSx = {
    menuButton: {
        marginRight: (theme: Theme) => theme.spacing(2)
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
            background: "linear-gradient(to bottom, black, darkred)"
        }
    },
    closeToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: (theme: Theme) => theme.spacing(2, 1, 2, 2),
        //...theme.mixins.toolbar TODO
    }
};

const CustomDrawer = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = (path: string) => (): void => {
        setOpen(false);
        if (location.pathname !== `/${path}`)
            navigate(`/${path}`);
    }

    return (
        <React.Fragment>
            <Box sx={drawerSx.menuButton}>
                <IconButton onClick={() => setOpen(true)}>
                    <MenuIcon/>
                </IconButton>
            </Box>
            <Drawer sx={drawerSx.drawer} open={open} onClose={() => setOpen(false)}>
                <Box sx={drawerSx.closeToolbar}>
                    <Link component="button" onClick={handleClick("")}>
                        <img src={"/logo.png"} height="40px" alt="logo"/>
                    </Link>
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Box>
                <Divider/>
                <List disablePadding>
                    <ListItemButton onClick={handleClick("mappa")}>
                        <ListItemIcon>
                            <MapOutlined/>
                        </ListItemIcon>
                        <ListItemText primary="Mappa Fucanoj"/>
                    </ListItemButton>
                    <Divider/>
                    <ListItemButton onClick={handleClick("programma")}>
                        <ListItemIcon>
                            <EventNote/>
                        </ListItemIcon>
                        <ListItemText primary="Programma"/>
                    </ListItemButton>
                    <Divider/>
                    <ListItemButton onClick={handleClick("san-valentino")}>
                        <ListItemIcon>
                            <Church/>
                        </ListItemIcon>
                        <ListItemText primary="San Valentino"/>
                    </ListItemButton>
                </List>
                <Stack direction="row" alignItems="center" marginTop="auto" padding={2} spacing={2}>
                    {/*<Typography variant="caption">{process.env.REACT_APP_NAME}</Typography>
                    <Typography variant="caption">{`version: ${process.env.REACT_APP_VERSION}`}</Typography>*/}
                    <Email/>
                    <Link href="mailto:sanvalentinoabriola@altervista.org" color="lightgrey" variant="body1" fontFamily="Papyrus">
                        sanvalentinoabriola@altervista.org
                    </Link>
                </Stack>
            </Drawer>
        </React.Fragment>
    );
}

export default CustomDrawer;

import React from "react";

interface InfoWindowContentProps {
    id: string;
    title: string;
    description: string;
}

const InfoWindowContent = (props: InfoWindowContentProps): JSX.Element => {
    const {id, title, description} = props;

    return (
        <div id="content" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2 id="title" style={{color: "darkred", marginBottom: "4px"}}>{title}</h2>
            <div id="description" style={{color: "darkred", marginBottom: "8px"}}>{description}</div>
            <button id={id} className="button">Naviga qui</button>
        </div>
    );
}

export default InfoWindowContent;
